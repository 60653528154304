// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artists-[name]-js": () => import("./../../../src/pages/artists/[name].js" /* webpackChunkName: "component---src-pages-artists-[name]-js" */),
  "component---src-pages-artists-index-js": () => import("./../../../src/pages/artists/index.js" /* webpackChunkName: "component---src-pages-artists-index-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-migrate-js": () => import("./../../../src/pages/migrate.js" /* webpackChunkName: "component---src-pages-migrate-js" */),
  "component---src-pages-mint-js": () => import("./../../../src/pages/mint.js" /* webpackChunkName: "component---src-pages-mint-js" */),
  "component---src-pages-mybrews-js": () => import("./../../../src/pages/mybrews.js" /* webpackChunkName: "component---src-pages-mybrews-js" */),
  "component---src-pages-nfts-js": () => import("./../../../src/pages/nfts.js" /* webpackChunkName: "component---src-pages-nfts-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-tokens-js": () => import("./../../../src/pages/tokens.js" /* webpackChunkName: "component---src-pages-tokens-js" */)
}

